<template>
	<figure class="course-editor-block-container">
		<figure><h2 class="course-editor-block-header" v-html="doMark(header)"></h2></figure>
		<ul v-if="!ordered">
			<li v-for="item in items" :key="item.id" v-html="doMark(item.content)"></li>
		</ul>
		<ol v-else>
			<li v-for="item in itemsContent" :key="item.id" v-html="doMark(item.content)"></li>
		</ol>
	</figure>
</template>

<script>
import marked from 'marked'
export default {
	name: "CourseEditorBlockList",
	props: {
		header: {
			type: String,
			require: true
		},
		headerLevel: {
			type: Number,
			require: true
		},
		ordered: {
			type: Number,
			require: true
		},
		items: {
			type: Array,
			require: true
		}
	},
	computed: {
		tag() {
			return `<h${this.headerLevel} class="course-editor-block-header">${this.header}</h${this.headerLevel}>`;
		}
	},
	methods: {
		doMark(e) {
			return marked(e)
		}
	}
};
</script>

<style>
.course-editor-block-header {
	font-size: 25px;
}
</style>
